import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header style={styles.header}>
      <nav style={styles.nav}>
        <Link to="/" style={styles.link}>Ana Sayfa</Link>
        <Link to="/kredi-hesaplama" style={styles.link}>Kredi Hesaplama</Link>
        <Link to="/mevduat-hesaplama" style={styles.link}>Mevduat Hesaplama</Link>
        <Link to="/iletisim" style={styles.link}>İletişim</Link>
      </nav>
    </header>
  );
};

const styles = {
  header: {
    backgroundColor: "#282c34",
    padding: "10px",
    textAlign: "center"
  },
  nav: {
    display: "flex",
    justifyContent: "center",
    gap: "20px"
  },
  link: {
    color: "white",
    textDecoration: "none",
    fontSize: "18px"
  }
};

export default Header;
